.actions {
    display: flex;
    justify-content: flex-end;
}

.button {
    color: white !important;
}

.buttonDisabled {
    color: #bbbbbb !important;
}
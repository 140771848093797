.default {
    background-color: #cbcbcb !important;
}

.platform {
    background-color: #00ccd3 !important;
    color: white !important;
}

.owner {
    background-color: #7728f1 !important;
    color: white !important;
}

.superadmin {
    background-color: #f18228 !important;
    color: white !important;
}

.admin {
    background-color: #f1c928 !important;
    color: black !important;
}

.cashier {
    background-color: #2fb000 !important;
    color: white !important;
}

.player {
    background-color: #c70046 !important;
    color: white !important;
}

.agent {
    background-color: #c259ff !important;
    color: white !important;
}
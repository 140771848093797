.container {
    width: 300px;
    display: flex;
    flex-direction: column;
    padding-right: 30px;
    row-gap: 10px;
}

.head {
    min-height: 64px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    column-gap: 12px;
}

.menuItemsSection {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}